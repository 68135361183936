.stripe label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

.stripe input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid #c4c4c4ff;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.stripe input::placeholder {
  color: #aab7c4;
}

.stripe input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.stripe .StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.stripe .StripeElement.PaymentRequestButton {
  height: 40px;
}

.powered-by-stripe {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: 0.9rem;
  color: #6772e5; /* Stripe's branding color */
  gap: 8px; /* Spacing between the logo and text */
  transition: color 0.3s ease;
}

.powered-by-stripe:hover {
  color: #5469d4; /* Darker blue on hover */
}

.powered-by-stripe img {
  height: 18px; /* Consistent logo size */
  width: auto; /* Maintain aspect ratio */
}
