@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;700;800&family=Inter:wght@200;300;400;500;600;700;800&family=Source+Sans+3:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300&display=swap");

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  margin: 0px !important;
  border-radius: 4px;
}

.MuiOutlinedInput-input {
  background-color: #fff !important;
  border-radius: 8px;
}
.modal-blur-background {
  filter: blur(5px);
  /* pointer-events: none; */
}

section {
  &#sessionExpired {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    position: relative;
    font-family: var(--roboto-font);
    .upperCorner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      height: 100%;
      background: #9370db;
      clip-path: polygon(0 0, 100% 0, 100% 100%);
    }
    .lowerCorner {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: #fff;
      clip-path: polygon(0 100%, 100% 100%, 0 0);
    }
    .card {
      padding: 2rem 10rem;
      transition: transform 0.3s;
    }
    .card:hover {
      transform: scale(1.02);
    }

    .card-body {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      gap: 1rem;
      flex-direction: column;
      .cardTitle {
        font-size: 2rem;
        font-weight: 500;
        color: #9370db;
      }
      .description {
        font-size: 1.5rem;
        letter-spacing: 0.1px;
        font-weight: 400;
        color: #9370db;
        padding-bottom: 1rem;
        text-align: center;
      }
      a {
        font-size: 1.5rem;
        padding: 0.5rem 2.5rem;
        font-weight: 500;
        background: #fff;
        border: none;
        border-radius: 2rem;
      }
    }
  }
}
